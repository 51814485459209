import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["template", "insertionPoint", "fieldSet"];
  static values = {
    alwaysAllowRemoval: Boolean,
  };

  connect() {
    this.element[this.identifier] = this;
    this.check_remove_button_visibility();
  }

  add_association(event) {
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    this.insertionPointTarget.insertAdjacentHTML("beforebegin", content);

    const fieldsets = this.fieldSetTargets;
    const lastFieldset = fieldsets[fieldsets.length - 1];
    let focusableField = lastFieldset.querySelector("trix-editor,input,select");
    if (focusableField) focusableField.focus();

    this.check_remove_button_visibility();

    this.dispatch("add", { detail: { fieldset: lastFieldset } })
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = this.fieldSetTargets.find((target) =>
      target.contains(event.target),
    );

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      let destroyField = wrapper.querySelector("input[name*='_destroy']");
      if (destroyField) {
        destroyField.value = 1;
      }
      wrapper.classList.add("hidden");
    }

    // If there is only one visible fieldset left, hide the remove button
    this.check_remove_button_visibility();
  }

  check_remove_button_visibility() {
    if (this.alwaysAllowRemovalValue) return;

    if (
      this.fieldSetTargets.filter(
        (target) => !target.classList.contains("hidden"),
      ).length == 1
    ) {
      let fieldSet = this.fieldSetTargets.find(
        (target) => !target.classList.contains("hidden"),
      );

      if (fieldSet.dataset.permanentRemoveBtn == "true") return;

      fieldSet
        .querySelector('[data-action="nested-form#remove_association"]')
        .classList.add("invisible");
    } else {
      this.element
        .querySelectorAll('[data-action="nested-form#remove_association"]')
        .forEach((button) => {
          button.classList.remove("invisible");
        });
    }
  }
}
