import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { with: String }
  static targets = [ "button" ]

  connect() {
    this.element.dataset['action'] = 'submit->disable#disableForm'
  }

  disableForm(event) {
    event.submitter.setAttribute('disabled', "disabled")
  }
}