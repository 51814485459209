import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "dropdownMenu", "form"];

  connect() {
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
  }

  selectAll() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = true);
  }

  reset() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false);
  }

  toggle() {
    this.dropdownMenuTarget.classList.toggle("hidden");
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownMenuTarget.classList.add("hidden");
    }
  }

  submit() {
    const formElement = this.element.querySelector("form");
    const formData = new FormData(formElement);
    const queryString = new URLSearchParams(formData).toString();
    const pathName = window.location.pathname;

    const url = queryString ? `${pathName}?${queryString}` : pathName;
    window.history.pushState({}, '', url);
  }
}
